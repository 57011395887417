<template>
	<div class="role">
	<tabbar :top1="top1" :top2="top2">
		<div class="titles1" style="margin-top: 10px;margin-right: 62%;">权限>角色设置</div>
		<!-- <toptitle :nameT="'角色列表'"></toptitle> -->
		<add @del='refresh()'></add>
		<contenttab :titles='listTitle' :contentC='data' @del='refresh()' :flag='flag'></contenttab>
	</tabbar>
	</div>
</template>

<script>
	import tabbar from '../public/Frame.vue'
	import toptitle from './comp/Title.vue';
	import contenttab from './comp/Content.vue';
	import add from './comp/Add.vue'
	export default{
		name:'role',
		data(){
			return{
				listTitle:null,
				data:null,
				flag:0,
				top1: '3-2',
				top2: ['3'],
			}
		},
		components:{
			toptitle,
			contenttab,
			add,
			tabbar
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.roleList({token}).then(res=>{
				this.data = res.data;
			})
			this.listTitle = ['角色名称','职能描述','添加时间','是否开启','操作']
		},
		methods:{
			refresh(obj){
				let token = this.$storage.getLocal('token');
				this.$request.roleList({token}).then(res=>{//列表数据
					this.data = res.data;
				})
			}
		},
		computed:{
			
		},
	}
</script>

<style scoped="scoped">
	.role
	{
	background-color: rgb(245,247,249);
	height: 800px;
	}
</style>
